import { trackEvent } from '@centered/analytics'
import * as React from 'react'
import { useIntersection } from 'react-use'
import ActiveLink from './ActiveLink'
import Wrapper from './Wrapper'
import { useRouter } from 'next/router'

const _LINKS = [
  {
    sectionLabel: 'Product',
    links: [
      {
        label: 'The Science of Flow',
        href: '/what-is-flow',
      },
      {
        label: 'Download Sukha',
        href: '/download',
        hideOnMobile: true,
      },
      {
        label: 'Changelog',
        href: 'https://thesukha.canny.io/changelog',
      },
      {
        label: 'FAQ',
        href: '/faq',
      },
    ],
  },
  {
    sectionLabel: 'Company',
    links: [
      {
        label: 'About',
        href: '/about',
      },
      {
        label: 'Blog',
        href: '/blog',
      },
    ],
  },
  {
    sectionLabel: 'Trust',
    links: [
      {
        label: 'Privacy Policy',
        href: '/privacy',
      },
      {
        label: 'Terms of Service',
        href: '/terms',
      },
      {
        label: 'Security',
        href: '/data-privacy',
      },
      {
        label: 'Limited Use Disclosure',
        href: '/limited-use-disclosure',
      },
    ],
  },
  {
    sectionLabel: 'Connect',
    links: [
      {
        label: 'Slack Community',
        href: 'https://join.slack.com/t/centeredcommunity/shared_invite/zt-gek4699x-j9Gh6QNnv8gKHXNeOM2VEQ',
      },
      {
        label: 'Contact Us',
        href: 'mailto:Support@TheSukha.co',
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/the_sukha_co',
      },
      {
        label: 'Instagram',
        href: 'https://www.instagram.com/the_sukha_co/',
      },
      {
        label: 'Facebook',
        href: 'https://www.facebook.com/thesukhacompany/',
      },
      {
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/company/the-sukha-company/',
      },
    ],
  },
]

const LINKS_SHORTENED = [
  
   {
     label: 'Click here to learn more about Sukha',
     href: '/full',
   },
   {
     label: 'Twitter',
     href: 'https://twitter.com/the_sukha_co',
   },
   {
     label: 'Download',
     href: '/download',
   },
  {
    label: 'Contact Us',
    href: 'mailto:support@thesukha.co',
  },
  {
    label: 'Privacy Policy',
    href: '/privacy',
  },
  {
    label: 'Terms of Service',
    href: '/terms',
  },
  {
    label: 'Security',
    href: '/data-privacy',
  },
] as const

interface FooterProps {
  onInView?: (inView: boolean) => void
  hideLearnMore?: boolean
}

export default function Footer({
  onInView,
  hideLearnMore,
}: FooterProps): JSX.Element {
  const imageContainerRef = React.useRef<HTMLDivElement>(null)
  const intersection = useIntersection(imageContainerRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  })
  const inView = intersection && intersection.isIntersecting
  const router = useRouter()
  const isFullPage = router.pathname === '/full';

  React.useEffect(() => {
    onInView?.(Boolean(inView))
  }, [inView])

  return (
    <footer className="pb-32 pt-8 md:pb-8 footer" ref={imageContainerRef}>
      <style jsx>{`
        .footer {
          background-color: #101010;
        }
      `}</style>

<Wrapper>
        <div className="flex flex-col gap-4 md:flex-row md:divide-y-0 md:justify-start">
          {LINKS_SHORTENED.filter((link) =>
            isFullPage ? true : link.href === '/full',
          ).map((link) => (
            <p key={link.href}>
              <NavLink text={link.label} href={link.href} />
            </p>
          ))}
        </div>

        <p className="text-white mt-10">© 2025 The Sukha Company</p>
      </Wrapper>
    </footer>
  )
}

function NavLink({ href, text }: { href: string; text: string }): JSX.Element {
  return (
    <ActiveLink
      href={href}
      text={text}
      onClick={() => {
        trackEvent('HomePageCTAClicked', {
          ctaSource: text.toLowerCase().includes('learn more')
            ? 'v3CTALearnMore'
            : 'footer link',
        })
      }}
      className="block text-white hover:text-opacity-70 transition-colors"
      activeClassName=""
    />
  )
}
